<template>
  <div>
    <el-header class="reaHead">
      <div class="index-one" @click="homeClick">
        <el-image :src="require('@/assets/logo.png')" alt=""></el-image>
        <div class="index-title">会务管理系统</div>
      </div>
    </el-header>
    <div style="margin-top: 20px">
      <div class="topTitle">
        会务人员注册
      </div>
      <div style="width: 40%;height: 1px;border-top: 1.5px solid #ccc;margin: 0 auto;margin-bottom: 10px;margin-top: 10px;"></div>
    </div>
    <div class="register-form">
      <el-form :model="registerForm" ref="registerForm" class="registerForm" :rules="registerFormRules" hide-required-asterisk label-position='right' label-width="90px">
        <el-form-item prop="name" label="姓名：" class="yzm">
          <el-input v-model="registerForm.name" type="text" class="yzm-phone" auto-complete="off" placeholder="请填写姓名" clearable></el-input>
        </el-form-item>
        <el-form-item prop="phone" label="手机号：" class="yzm">
          <el-input v-model="registerForm.phone" type="text" class="yzm-phone" auto-complete="off" placeholder="请填写手机号" clearable></el-input>
        </el-form-item>
        <el-form-item prop="yzm" class="yzm" label="验证码：">
          <el-input v-model="registerForm.yzm" type="password" placeholder="请填写验证码" class="login-yzm"></el-input>
          <el-button :disabled="isClick" :class="[isClick ? 'login-isgetyzm' : 'login-getyzm']" class="" @click="sms">{{ yzmcontent }}</el-button>
        </el-form-item>
        <el-form-item prop="email" label="邮箱：" class="yzm">
          <el-input v-model="registerForm.email" type="text" class="yzm-phone" auto-complete="off" placeholder="请填写邮箱" clearable></el-input>
        </el-form-item>
        <el-form-item label="性别：" prop="sex" class="yzm">
          <el-select v-model="registerForm.sex" placeholder="请选择性别" class="yzm-phone">
            <el-option v-for="item in sexSelect" :key="item.id" :label="item.name" :value="item.id" auto-complete="off">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="会议：" prop="meetingId" class="yzm">
          <el-select v-model="registerForm.meetingId" placeholder="请选择负责的会议" class="yzm-phone" filterable @change="meetingChange(registerForm.meetingId)">
            <el-option v-for="item in meetingSelect" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="会场：" prop="conferenceId" class="yzm">
          <el-select v-model="registerForm.conferenceId" placeholder="请选择负责的会场" class="yzm-phone" filterable>
            <el-option v-for="item in conferenceSelect" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>

        <el-button class="next-button" @click="registerSubmit">提交</el-button>
      </el-form>
    </div>
  </div>
</template>
<script>
import _qs from "qs";
export default {
  data() {
    var checkAge = (rule, value, callback) => {
      if (!value && String(value) !== "0") {
        return callback(new Error("不能为空"));
      }
      setTimeout(() => {
        if (!/^1[3|4|5|7|8][0-9]\d{8}$/.test(value)) {
          callback(new Error("请输入正确的手机号"));
        } else {
          callback();
        }
      }, 100);
    };
    var checkEmail = (rule, value, cb) => {
      // 验证邮箱的正则表达式
      const regEmail = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(\.[a-zA-Z0-9_-])+/
      if (regEmail.test(value)) {
        //合法的邮箱
        return cb()
      }
      cb(new Error('请输入合法的邮箱'))
    }
    return {
      isClick: false,
      yzmcontent: "获取验证码",
      registerFormRules: {
        name: [
          { required: true, message: '请输入姓名', trigger: "blur" },
        ],
        phone: [
          { required: true, validator: checkAge, trigger: "blur" },
        ],
        yzm: [
          { required: true, message: '请输入验证码', trigger: "blur" },
        ],
        email: [
          { required: true, message: '请输入邮箱', trigger: "blur" },
          { validator: checkEmail, trigger: 'blur' },
        ],
        sex: [
          { required: true, message: '请输入性别', trigger: "blur" },
        ],
        conferenceId: [
          { required: true, message: '请选择负责的会场', trigger: "blur" },
        ],
        meetingId: [
          { required: true, message: '请选择负责的会议', trigger: "blur" },
        ],
      },
      sexSelect:[
        {name:'男',id:'男'},
        {name:'女',id:'女'},
      ],
      meetingSelect:[],
      conferenceSelect:[],
      // 验证码登录
      registerForm: {
        name: "",
        phone: "",
        yzm: "",
        email: "",
        sex: "",
        conferenceId: "",
        meetingId: ""
      },
    };
  },
  created() {
    this.meetingList();
  },
  methods: {
    async meetingChange(val){
      let data = _qs.stringify({
        meetingId:val,
        status:0
      });
      let { data: res } =await this.$axios.conferenceList(data);
      if (res.code == 401) {
        this.$router.push("/login");
      } else if (res.code == 200) {
        this.registerForm.conferenceId = '';
        this.conferenceSelect = res.data;
      }else{
        this.$message({
          message: res.msg,
          type: "error",
        });
      }
    },
    homeClick(){
      this.$router.push("/login");
    },
    // 会议列表
    async meetingList(){
      let data = _qs.stringify({
        status:0 // 进行中
      });
      let { data: res } = await this.$axios.meetingList(data);
      // console.log(res.data);
      if (res.code == 401) {
        this.$router.push("/login");
      } else if (res.code == 200) {
        this.meetingSelect = res.data;
      }else{
        this.$message({
          message: res.msg,
          type: "error",
        });
      }
    },
    // 点击获取验证码
    async sms() {
      if(!this.registerForm.phone){
        this.$message.error("请输入手机号");
        return false;
      }
      if (!/^1[3|4|5|7|8][0-9]\d{8}$/.test(this.registerForm.phone)) {
        this.$message.error("请输入正确的手机号");
        return false;
      }
      let snum = 60;
      this.timer = setInterval(() => {
        snum--;
        this.yzmcontent = "重新获取(" + snum + "s)";
        this.isClick = true;
        if (snum === 0) {
          clearInterval(this.timer);
          this.yzmcontent = "获取验证码";
          this.isClick = false;
          return;
        }
      }, 1000);
      let data = _qs.stringify({
        phone: this.registerForm.phone,
      });
      let { data: res } = await this.$axios.registerSms(data);
      // console.log(res);
      if (res.code == 200) {
        this.$message({
          message: "获取验证码成功",
          type: "success",
        });
      } else if (res.code == 401) {
        this.$router.push("/login");
      } else if (res.code == 500) {
        this.$message({
          message: "获取验证码失败",
          type: "error",
        });
      }
    },
    // 验证码登录
    async registerSubmit() {
      this.$refs.registerForm.validate(async (valid) => {
        if(valid){
          // 验证验证码
          let data = _qs.stringify({
            phone: this.registerForm.phone,
            code: this.registerForm.yzm
          });
          let res = await this.$axios.verifyForgetCode(data);
          if (res.data.code == 200) {
            // 提交注册信息
            let registerData = _qs.stringify({
              username: this.registerForm.name,
              sex: this.registerForm.sex,
              phone: this.registerForm.phone,
              email: this.registerForm.email,
              conferenceId: this.registerForm.conferenceId
            });

            let registerRes = await this.$axios.registerSubmit(registerData);
            if (registerRes.data.code == 200) {
              this.$router.push("/registerOk");
            }else{
              this.$message.error(registerRes.data.msg);
              return false;
            }
          } else if (res.data.code == 500) {
            this.$message.error("验证码错误！请重新获取");
            this.registerForm.yzm = "";
            return false;
          }
        } else {
          return false;
        }
      })


    },
  },
};
</script>

<style lang="less" scoped>

.topTitle{
  width: 40%;
  margin: 0 auto;
  text-align: center;
  font-weight: 700;
  font-size: 18px;
}

.yzm {
  width: 100%;
  //display: flex;
  flex-direction: row;
  .login-yzm {
    width: 60%;
  }
  .login-getyzm {
    width: 40%;
    background: #0380dd;
    color: white;
  }
  .login-isgetyzm {
    width: 40%;
    background: #ccc;
    color: white;
  }
  .yzm-phone{
    width: 100%;
  }
}
.next-button {
  width: 80%;
  position: relative;
  background-image: linear-gradient(to right, #00c3ff, #00abff, #0095ff);
  color: white;
  font-weight: bolder;
  margin-left: 40px;
  margin-top: 10px;
}
.register-form {
  width: 38%;
  height: 500px;
  background: white;
  display: flex;
  padding: 10px 20px;
  box-shadow: 0 0 16px #ccc;
  border-radius: 20px 20px 20px 20px;
  margin: 0 auto;
  margin-top: 20px;
  text-align: center;
}
.registerForm{
  width: 60%;
  //height: 500px;
  background: white;
  //box-shadow: 0 0 16px #ccc;
  //border-radius: 20px 20px 20px 20px;
  margin: 0 auto;
  text-align: center;
  padding-right: 20px;
}
/deep/ .reaHead {
  position: relative;
  width: 100%;
  height: 80px !important;
  display: flex;
  align-items: center;
  //background: rgba(0, 0, 0, 0.6);
  background:#1b63c1 !important;
  color: white;
  justify-content: space-between;
  .index-title {
    font-size: 30px;
  }
  .index-one {
    display: flex;
    position: relative;
    align-items: center;
  }
  .index-one:hover {
    cursor: pointer;
  }

  .el-image__inner {
    width: 55%;
  }
  /deep/ .el-input--prefix .el-input__inner {
    border-radius: 20px;
  }
}
</style>
